// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "common.proto" (package "rs.devtools.common", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * A Rust source code location.
 *
 * @generated from protobuf message rs.devtools.common.Location
 */
export interface Location {
    /**
     * The file path
     *
     * @generated from protobuf field: optional string file = 1;
     */
    file?: string;
    /**
     * The Rust module path
     *
     * @generated from protobuf field: optional string module_path = 2;
     */
    modulePath?: string;
    /**
     * The line number in the source code file.
     *
     * @generated from protobuf field: optional uint32 line = 3;
     */
    line?: number;
    /**
     * The character in `line`.
     *
     * @generated from protobuf field: optional uint32 column = 4;
     */
    column?: number;
}
/**
 * Metadata associated with an event of span.
 *
 * @generated from protobuf message rs.devtools.common.Metadata
 */
export interface Metadata {
    /**
     * The name of the span or event.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Describes the part of the system where the span or event that this
     * metadata describes occurred.
     *
     * @generated from protobuf field: string target = 2;
     */
    target: string;
    /**
     * The Rust source location associated with the span or event.
     *
     * @generated from protobuf field: rs.devtools.common.Location location = 3;
     */
    location?: Location;
    /**
     * Indicates whether metadata is associated with a span or with an event.
     *
     * @generated from protobuf field: rs.devtools.common.Metadata.Kind kind = 4;
     */
    kind: Metadata_Kind;
    /**
     * Describes the level of verbosity of a span or event.
     *
     * @generated from protobuf field: rs.devtools.common.Metadata.Level level = 5;
     */
    level: Metadata_Level;
    /**
     * The names of the key-value fields attached to the
     * span or event this metadata is associated with.
     *
     * @generated from protobuf field: repeated string field_names = 6;
     */
    fieldNames: string[];
}
/**
 * Indicates whether metadata is associated with a span or with an event.
 *
 * @generated from protobuf enum rs.devtools.common.Metadata.Kind
 */
export enum Metadata_Kind {
    /**
     * Indicates metadata is associated with a span.
     *
     * @generated from protobuf enum value: SPAN = 0;
     */
    SPAN = 0,
    /**
     * Indicates metadata is associated with an event.
     *
     * @generated from protobuf enum value: EVENT = 1;
     */
    EVENT = 1
}
/**
 * Describes the level of verbosity of a span or event.
 *
 * Corresponds to `Level` in the `tracing` crate.
 *
 * @generated from protobuf enum rs.devtools.common.Metadata.Level
 */
export enum Metadata_Level {
    /**
     * The "error" level.
     *
     * Designates very serious errors.
     *
     * @generated from protobuf enum value: ERROR = 0;
     */
    ERROR = 0,
    /**
     * The "warn" level.
     *
     * Designates hazardous situations.
     *
     * @generated from protobuf enum value: WARN = 1;
     */
    WARN = 1,
    /**
     * The "info" level.
     * Designates useful information.
     *
     * @generated from protobuf enum value: INFO = 2;
     */
    INFO = 2,
    /**
     * The "debug" level.
     *
     * Designates lower priority information.
     *
     * @generated from protobuf enum value: DEBUG = 3;
     */
    DEBUG = 3,
    /**
     * The "trace" level.
     *
     * Designates very low priority, often extremely verbose, information.
     *
     * @generated from protobuf enum value: TRACE = 4;
     */
    TRACE = 4
}
/**
 * One metadata element registered since the last update.
 *
 * @generated from protobuf message rs.devtools.common.NewMetadata
 */
export interface NewMetadata {
    /**
     * Unique identifier for `metadata`.
     *
     * @generated from protobuf field: optional uint64 id = 1;
     */
    id?: bigint;
    /**
     * The metadata payload.
     *
     * @generated from protobuf field: rs.devtools.common.Metadata metadata = 2;
     */
    metadata?: Metadata;
}
/**
 * A message representing a key-value pair of data associated with a `Span`
 *
 * @generated from protobuf message rs.devtools.common.Field
 */
export interface Field {
    /**
     * The key of the key-value pair.
     *
     * This is either represented as a string, or as an index into a `Metadata`'s
     * array of field name strings.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf oneof: value
     */
    value: {
        oneofKind: "debugVal";
        /**
         * A value serialized to a string using `fmt::Debug`.
         *
         * @generated from protobuf field: string debug_val = 2;
         */
        debugVal: string;
    } | {
        oneofKind: "strVal";
        /**
         * A string value.
         *
         * @generated from protobuf field: string str_val = 3;
         */
        strVal: string;
    } | {
        oneofKind: "u64Val";
        /**
         * An unsigned integer value.
         *
         * @generated from protobuf field: uint64 u64_val = 4;
         */
        u64Val: bigint;
    } | {
        oneofKind: "i64Val";
        /**
         * A signed integer value.
         *
         * @generated from protobuf field: sint64 i64_val = 5;
         */
        i64Val: bigint;
    } | {
        oneofKind: "boolVal";
        /**
         * A boolean value.
         *
         * @generated from protobuf field: bool bool_val = 6;
         */
        boolVal: boolean;
    } | {
        oneofKind: "doubleVal";
        /**
         * A double (f64) value.
         *
         * @generated from protobuf field: double double_val = 7;
         */
        doubleVal: number;
    } | {
        oneofKind: undefined;
    };
    /**
     * Metadata for the task span that the field came from.
     *
     * @generated from protobuf field: uint64 metadata_id = 8;
     */
    metadataId: bigint;
}
// @generated message type with reflection information, may provide speed optimized methods
class Location$Type extends MessageType<Location> {
    constructor() {
        super("rs.devtools.common.Location", [
            { no: 1, name: "file", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "module_path", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "line", kind: "scalar", opt: true, T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "column", kind: "scalar", opt: true, T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<Location>): Location {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Location>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Location): Location {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional string file */ 1:
                    message.file = reader.string();
                    break;
                case /* optional string module_path */ 2:
                    message.modulePath = reader.string();
                    break;
                case /* optional uint32 line */ 3:
                    message.line = reader.uint32();
                    break;
                case /* optional uint32 column */ 4:
                    message.column = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Location, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional string file = 1; */
        if (message.file !== undefined)
            writer.tag(1, WireType.LengthDelimited).string(message.file);
        /* optional string module_path = 2; */
        if (message.modulePath !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.modulePath);
        /* optional uint32 line = 3; */
        if (message.line !== undefined)
            writer.tag(3, WireType.Varint).uint32(message.line);
        /* optional uint32 column = 4; */
        if (message.column !== undefined)
            writer.tag(4, WireType.Varint).uint32(message.column);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.common.Location
 */
export const Location = new Location$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Metadata$Type extends MessageType<Metadata> {
    constructor() {
        super("rs.devtools.common.Metadata", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "target", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "location", kind: "message", T: () => Location },
            { no: 4, name: "kind", kind: "enum", T: () => ["rs.devtools.common.Metadata.Kind", Metadata_Kind] },
            { no: 5, name: "level", kind: "enum", T: () => ["rs.devtools.common.Metadata.Level", Metadata_Level] },
            { no: 6, name: "field_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Metadata>): Metadata {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.target = "";
        message.kind = 0;
        message.level = 0;
        message.fieldNames = [];
        if (value !== undefined)
            reflectionMergePartial<Metadata>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Metadata): Metadata {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string target */ 2:
                    message.target = reader.string();
                    break;
                case /* rs.devtools.common.Location location */ 3:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rs.devtools.common.Metadata.Kind kind */ 4:
                    message.kind = reader.int32();
                    break;
                case /* rs.devtools.common.Metadata.Level level */ 5:
                    message.level = reader.int32();
                    break;
                case /* repeated string field_names */ 6:
                    message.fieldNames.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Metadata, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string target = 2; */
        if (message.target !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.target);
        /* rs.devtools.common.Location location = 3; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rs.devtools.common.Metadata.Kind kind = 4; */
        if (message.kind !== 0)
            writer.tag(4, WireType.Varint).int32(message.kind);
        /* rs.devtools.common.Metadata.Level level = 5; */
        if (message.level !== 0)
            writer.tag(5, WireType.Varint).int32(message.level);
        /* repeated string field_names = 6; */
        for (let i = 0; i < message.fieldNames.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldNames[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.common.Metadata
 */
export const Metadata = new Metadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewMetadata$Type extends MessageType<NewMetadata> {
    constructor() {
        super("rs.devtools.common.NewMetadata", [
            { no: 1, name: "id", kind: "scalar", opt: true, T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "metadata", kind: "message", T: () => Metadata }
        ]);
    }
    create(value?: PartialMessage<NewMetadata>): NewMetadata {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<NewMetadata>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewMetadata): NewMetadata {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional uint64 id */ 1:
                    message.id = reader.uint64().toBigInt();
                    break;
                case /* rs.devtools.common.Metadata metadata */ 2:
                    message.metadata = Metadata.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewMetadata, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional uint64 id = 1; */
        if (message.id !== undefined)
            writer.tag(1, WireType.Varint).uint64(message.id);
        /* rs.devtools.common.Metadata metadata = 2; */
        if (message.metadata)
            Metadata.internalBinaryWrite(message.metadata, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.common.NewMetadata
 */
export const NewMetadata = new NewMetadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Field$Type extends MessageType<Field> {
    constructor() {
        super("rs.devtools.common.Field", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "debug_val", kind: "scalar", oneof: "value", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "str_val", kind: "scalar", oneof: "value", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "u64_val", kind: "scalar", oneof: "value", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "i64_val", kind: "scalar", oneof: "value", T: 18 /*ScalarType.SINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 6, name: "bool_val", kind: "scalar", oneof: "value", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "double_val", kind: "scalar", oneof: "value", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "metadata_id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<Field>): Field {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.value = { oneofKind: undefined };
        message.metadataId = 0n;
        if (value !== undefined)
            reflectionMergePartial<Field>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Field): Field {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string debug_val */ 2:
                    message.value = {
                        oneofKind: "debugVal",
                        debugVal: reader.string()
                    };
                    break;
                case /* string str_val */ 3:
                    message.value = {
                        oneofKind: "strVal",
                        strVal: reader.string()
                    };
                    break;
                case /* uint64 u64_val */ 4:
                    message.value = {
                        oneofKind: "u64Val",
                        u64Val: reader.uint64().toBigInt()
                    };
                    break;
                case /* sint64 i64_val */ 5:
                    message.value = {
                        oneofKind: "i64Val",
                        i64Val: reader.sint64().toBigInt()
                    };
                    break;
                case /* bool bool_val */ 6:
                    message.value = {
                        oneofKind: "boolVal",
                        boolVal: reader.bool()
                    };
                    break;
                case /* double double_val */ 7:
                    message.value = {
                        oneofKind: "doubleVal",
                        doubleVal: reader.double()
                    };
                    break;
                case /* uint64 metadata_id */ 8:
                    message.metadataId = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Field, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string debug_val = 2; */
        if (message.value.oneofKind === "debugVal")
            writer.tag(2, WireType.LengthDelimited).string(message.value.debugVal);
        /* string str_val = 3; */
        if (message.value.oneofKind === "strVal")
            writer.tag(3, WireType.LengthDelimited).string(message.value.strVal);
        /* uint64 u64_val = 4; */
        if (message.value.oneofKind === "u64Val")
            writer.tag(4, WireType.Varint).uint64(message.value.u64Val);
        /* sint64 i64_val = 5; */
        if (message.value.oneofKind === "i64Val")
            writer.tag(5, WireType.Varint).sint64(message.value.i64Val);
        /* bool bool_val = 6; */
        if (message.value.oneofKind === "boolVal")
            writer.tag(6, WireType.Varint).bool(message.value.boolVal);
        /* double double_val = 7; */
        if (message.value.oneofKind === "doubleVal")
            writer.tag(7, WireType.Bit64).double(message.value.doubleVal);
        /* uint64 metadata_id = 8; */
        if (message.metadataId !== 0n)
            writer.tag(8, WireType.Varint).uint64(message.metadataId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.common.Field
 */
export const Field = new Field$Type();
