// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "health.proto" (package "grpc.health.v1", syntax proto3)
// tslint:disable
//
// The definition for the official gRPC health checking service
// as defined here https://github.com/grpc/grpc/blob/master/doc/health-checking.md
// This service should be used by clients to determine whether the instrumentation is still running.
//
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Health } from "./health";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { HealthCheckResponse } from "./health";
import type { HealthCheckRequest } from "./health";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service grpc.health.v1.Health
 */
export interface IHealthClient {
    /**
     * @generated from protobuf rpc: Check(grpc.health.v1.HealthCheckRequest) returns (grpc.health.v1.HealthCheckResponse);
     */
    check(input: HealthCheckRequest, options?: RpcOptions): UnaryCall<HealthCheckRequest, HealthCheckResponse>;
    /**
     * @generated from protobuf rpc: Watch(grpc.health.v1.HealthCheckRequest) returns (stream grpc.health.v1.HealthCheckResponse);
     */
    watch(input: HealthCheckRequest, options?: RpcOptions): ServerStreamingCall<HealthCheckRequest, HealthCheckResponse>;
}
/**
 * @generated from protobuf service grpc.health.v1.Health
 */
export class HealthClient implements IHealthClient, ServiceInfo {
    typeName = Health.typeName;
    methods = Health.methods;
    options = Health.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: Check(grpc.health.v1.HealthCheckRequest) returns (grpc.health.v1.HealthCheckResponse);
     */
    check(input: HealthCheckRequest, options?: RpcOptions): UnaryCall<HealthCheckRequest, HealthCheckResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<HealthCheckRequest, HealthCheckResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Watch(grpc.health.v1.HealthCheckRequest) returns (stream grpc.health.v1.HealthCheckResponse);
     */
    watch(input: HealthCheckRequest, options?: RpcOptions): ServerStreamingCall<HealthCheckRequest, HealthCheckResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<HealthCheckRequest, HealthCheckResponse>("serverStreaming", this._transport, method, opt, input);
    }
}
