// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "sources.proto" (package "rs.devtools.sources", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Sources } from "./sources";
import type { Chunk } from "./sources";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Entry } from "./sources";
import type { EntryRequest } from "./sources";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service rs.devtools.sources.Sources
 */
export interface ISourcesClient {
    /**
     * Returns the entries of a directory in a streaming way. The idea is that this helps time-to-first-paint especially when the
     * folder is large. The client can start rendering the entries as they come in.
     *
     * Notes:
     * - All paths are relative to the workspace root. The idea is that clients do not need to know the absolute position of a workspace and with workspace-relative paths we can reduce the amount of PII sent.
     * - This API DOES NOT recursively list workspace entries. The idea is that the client renders a tree-view with all sub-folder collapsed by default and issue a new list_entries call for a sub-folder when a tree node is expanded.
     * - File type is a set of bitflags that represent the various properties of the entry. See the `Entry` message for more details.
     *
     * @generated from protobuf rpc: ListEntries(rs.devtools.sources.EntryRequest) returns (stream rs.devtools.sources.Entry);
     */
    listEntries(input: EntryRequest, options?: RpcOptions): ServerStreamingCall<EntryRequest, Entry>;
    /**
     * Returns the bytes of a file in a streaming way. The idea is that this helps time-to-first-paint especially when the file is large.
     * This is done, again, to optimize the time to first paint for assets that are streaming compatible such as images.
     *
     * @generated from protobuf rpc: GetEntryBytes(rs.devtools.sources.EntryRequest) returns (stream rs.devtools.sources.Chunk);
     */
    getEntryBytes(input: EntryRequest, options?: RpcOptions): ServerStreamingCall<EntryRequest, Chunk>;
}
/**
 * @generated from protobuf service rs.devtools.sources.Sources
 */
export class SourcesClient implements ISourcesClient, ServiceInfo {
    typeName = Sources.typeName;
    methods = Sources.methods;
    options = Sources.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Returns the entries of a directory in a streaming way. The idea is that this helps time-to-first-paint especially when the
     * folder is large. The client can start rendering the entries as they come in.
     *
     * Notes:
     * - All paths are relative to the workspace root. The idea is that clients do not need to know the absolute position of a workspace and with workspace-relative paths we can reduce the amount of PII sent.
     * - This API DOES NOT recursively list workspace entries. The idea is that the client renders a tree-view with all sub-folder collapsed by default and issue a new list_entries call for a sub-folder when a tree node is expanded.
     * - File type is a set of bitflags that represent the various properties of the entry. See the `Entry` message for more details.
     *
     * @generated from protobuf rpc: ListEntries(rs.devtools.sources.EntryRequest) returns (stream rs.devtools.sources.Entry);
     */
    listEntries(input: EntryRequest, options?: RpcOptions): ServerStreamingCall<EntryRequest, Entry> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<EntryRequest, Entry>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Returns the bytes of a file in a streaming way. The idea is that this helps time-to-first-paint especially when the file is large.
     * This is done, again, to optimize the time to first paint for assets that are streaming compatible such as images.
     *
     * @generated from protobuf rpc: GetEntryBytes(rs.devtools.sources.EntryRequest) returns (stream rs.devtools.sources.Chunk);
     */
    getEntryBytes(input: EntryRequest, options?: RpcOptions): ServerStreamingCall<EntryRequest, Chunk> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<EntryRequest, Chunk>("serverStreaming", this._transport, method, opt, input);
    }
}
