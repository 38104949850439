type Props = {
  size: number;
};

export const Logo = (props: Props) => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 170 204"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M85.364,39.612c-33.741,44.485 -33.545,101.32 0.436,126.945c8.931,6.734 19.306,10.643 30.362,11.938c-31.363,25.473 -67.492,33.378 -91.886,16.787c-33.455,-22.753 -32.173,-82.962 2.865,-134.48c29.257,-43.019 72.799,-66.375 105.849,-59.663c-17.457,7.72 -34.213,20.79 -47.626,38.473Zm44.334,56.813c-15.758,14.892 -33.768,24.791 -48.973,28.084c8.978,32.091 43.491,34.558 43.491,34.558c0,0 20.539,-4.724 36.049,-39.57c12.772,-28.695 11.659,-58.346 -1.215,-73.436c-1.069,14.584 -11.58,33.571 -29.352,50.364Z" />
  </svg>
);
