// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "instrument.proto" (package "rs.devtools.instrument", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Update as Update$2 } from "./spans";
import { Update as Update$ } from "./logs";
import { NewMetadata } from "./common";
import { Timestamp } from "./google/protobuf/timestamp";
import { Metadata_Level } from "./common";
/**
 * @generated from protobuf message rs.devtools.instrument.InstrumentRequest
 */
export interface InstrumentRequest {
    /**
     * Allows filtering the log events.
     *
     * @generated from protobuf field: rs.devtools.instrument.Filter log_filter = 2;
     */
    logFilter?: Filter;
    /**
     * Allows filtering the span events.
     *
     * @generated from protobuf field: rs.devtools.instrument.Filter span_filter = 3;
     */
    spanFilter?: Filter;
}
/**
 * A filter configuration
 * You can filter by level, file, log message or a combination of all three.
 *
 * @generated from protobuf message rs.devtools.instrument.Filter
 */
export interface Filter {
    /**
     * @generated from protobuf field: optional rs.devtools.common.Metadata.Level level = 1;
     */
    level?: Metadata_Level;
    /**
     * @generated from protobuf field: optional string file = 2;
     */
    file?: string;
    /**
     * @generated from protobuf field: optional string text = 3;
     */
    text?: string;
}
/**
 * An update about the state of the instrumented application.
 *
 * An updated is comprised of a set of sub-updates about each tracked data source,
 * they are combined into one message however to reduce the complexity for both
 * the server and client as well as deduplicate data between updates:
 * - a single timestamp for all updates
 * - a single place for new_metadata
 *
 * @generated from protobuf message rs.devtools.instrument.Update
 */
export interface Update {
    /**
     * The system time when this update was recorded.
     *
     * @generated from protobuf field: google.protobuf.Timestamp at = 1;
     */
    at?: Timestamp;
    /**
     * Any new metadata that was registered since the last update.
     *
     * The metadata_id fields in `LogEvent` and `Span` refer back to metadata registered through these updates.
     *
     * @generated from protobuf field: repeated rs.devtools.common.NewMetadata new_metadata = 2;
     */
    newMetadata: NewMetadata[];
    /**
     * Log events update.
     *
     * @generated from protobuf field: rs.devtools.logs.Update logs_update = 3;
     */
    logsUpdate?: Update$;
    /**
     * Span events update.
     *
     * @generated from protobuf field: rs.devtools.spans.Update spans_update = 4;
     */
    spansUpdate?: Update$2;
}
// @generated message type with reflection information, may provide speed optimized methods
class InstrumentRequest$Type extends MessageType<InstrumentRequest> {
    constructor() {
        super("rs.devtools.instrument.InstrumentRequest", [
            { no: 2, name: "log_filter", kind: "message", T: () => Filter },
            { no: 3, name: "span_filter", kind: "message", T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<InstrumentRequest>): InstrumentRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<InstrumentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InstrumentRequest): InstrumentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rs.devtools.instrument.Filter log_filter */ 2:
                    message.logFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.logFilter);
                    break;
                case /* rs.devtools.instrument.Filter span_filter */ 3:
                    message.spanFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.spanFilter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InstrumentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rs.devtools.instrument.Filter log_filter = 2; */
        if (message.logFilter)
            Filter.internalBinaryWrite(message.logFilter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rs.devtools.instrument.Filter span_filter = 3; */
        if (message.spanFilter)
            Filter.internalBinaryWrite(message.spanFilter, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.instrument.InstrumentRequest
 */
export const InstrumentRequest = new InstrumentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Filter$Type extends MessageType<Filter> {
    constructor() {
        super("rs.devtools.instrument.Filter", [
            { no: 1, name: "level", kind: "enum", opt: true, T: () => ["rs.devtools.common.Metadata.Level", Metadata_Level] },
            { no: 2, name: "file", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "text", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Filter>): Filter {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Filter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Filter): Filter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional rs.devtools.common.Metadata.Level level */ 1:
                    message.level = reader.int32();
                    break;
                case /* optional string file */ 2:
                    message.file = reader.string();
                    break;
                case /* optional string text */ 3:
                    message.text = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Filter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional rs.devtools.common.Metadata.Level level = 1; */
        if (message.level !== undefined)
            writer.tag(1, WireType.Varint).int32(message.level);
        /* optional string file = 2; */
        if (message.file !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.file);
        /* optional string text = 3; */
        if (message.text !== undefined)
            writer.tag(3, WireType.LengthDelimited).string(message.text);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.instrument.Filter
 */
export const Filter = new Filter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Update$Type extends MessageType<Update> {
    constructor() {
        super("rs.devtools.instrument.Update", [
            { no: 1, name: "at", kind: "message", T: () => Timestamp },
            { no: 2, name: "new_metadata", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NewMetadata },
            { no: 3, name: "logs_update", kind: "message", T: () => Update$ },
            { no: 4, name: "spans_update", kind: "message", T: () => Update$2 }
        ]);
    }
    create(value?: PartialMessage<Update>): Update {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.newMetadata = [];
        if (value !== undefined)
            reflectionMergePartial<Update>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Update): Update {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp at */ 1:
                    message.at = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.at);
                    break;
                case /* repeated rs.devtools.common.NewMetadata new_metadata */ 2:
                    message.newMetadata.push(NewMetadata.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rs.devtools.logs.Update logs_update */ 3:
                    message.logsUpdate = Update$.internalBinaryRead(reader, reader.uint32(), options, message.logsUpdate);
                    break;
                case /* rs.devtools.spans.Update spans_update */ 4:
                    message.spansUpdate = Update$2.internalBinaryRead(reader, reader.uint32(), options, message.spansUpdate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Update, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp at = 1; */
        if (message.at)
            Timestamp.internalBinaryWrite(message.at, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated rs.devtools.common.NewMetadata new_metadata = 2; */
        for (let i = 0; i < message.newMetadata.length; i++)
            NewMetadata.internalBinaryWrite(message.newMetadata[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rs.devtools.logs.Update logs_update = 3; */
        if (message.logsUpdate)
            Update$.internalBinaryWrite(message.logsUpdate, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rs.devtools.spans.Update spans_update = 4; */
        if (message.spansUpdate)
            Update$2.internalBinaryWrite(message.spansUpdate, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.instrument.Update
 */
export const Update = new Update$Type();
/**
 * @generated ServiceType for protobuf service rs.devtools.instrument.Instrument
 */
export const Instrument = new ServiceType("rs.devtools.instrument.Instrument", [
    { name: "WatchUpdates", serverStreaming: true, options: {}, I: InstrumentRequest, O: Update }
]);
