// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "tauri.proto" (package "rs.devtools.tauri", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "./google/protobuf/timestamp";
/**
 * @generated from protobuf message rs.devtools.tauri.VersionsRequest
 */
export interface VersionsRequest {
}
/**
 * @generated from protobuf message rs.devtools.tauri.Versions
 */
export interface Versions {
    /**
     * / Tauri version, follows SemVer.
     *
     * @generated from protobuf field: string tauri = 1;
     */
    tauri: string;
    /**
     * / Version of the OS'es webview.
     * / The format of this is freeform and changes depending on the OS.
     *
     * @generated from protobuf field: optional string webview = 2;
     */
    webview?: string;
}
/**
 * @generated from protobuf message rs.devtools.tauri.ConfigRequest
 */
export interface ConfigRequest {
}
/**
 * @generated from protobuf message rs.devtools.tauri.MetricsRequest
 */
export interface MetricsRequest {
}
/**
 * @generated from protobuf message rs.devtools.tauri.Config
 */
export interface Config {
    /**
     * / The raw JSON string of the configuration
     *
     * @generated from protobuf field: string raw = 1;
     */
    raw: string;
}
/**
 * @generated from protobuf message rs.devtools.tauri.Metrics
 */
export interface Metrics {
    /**
     * / Tauri application initialization time
     *
     * @generated from protobuf field: google.protobuf.Timestamp initialized_at = 1;
     */
    initializedAt?: Timestamp;
    /**
     * / Tauri application reported `AppReady` time
     *
     * @generated from protobuf field: google.protobuf.Timestamp ready_at = 2;
     */
    readyAt?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class VersionsRequest$Type extends MessageType<VersionsRequest> {
    constructor() {
        super("rs.devtools.tauri.VersionsRequest", []);
    }
    create(value?: PartialMessage<VersionsRequest>): VersionsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<VersionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VersionsRequest): VersionsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: VersionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.tauri.VersionsRequest
 */
export const VersionsRequest = new VersionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Versions$Type extends MessageType<Versions> {
    constructor() {
        super("rs.devtools.tauri.Versions", [
            { no: 1, name: "tauri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "webview", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Versions>): Versions {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tauri = "";
        if (value !== undefined)
            reflectionMergePartial<Versions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Versions): Versions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tauri */ 1:
                    message.tauri = reader.string();
                    break;
                case /* optional string webview */ 2:
                    message.webview = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Versions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tauri = 1; */
        if (message.tauri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tauri);
        /* optional string webview = 2; */
        if (message.webview !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.webview);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.tauri.Versions
 */
export const Versions = new Versions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConfigRequest$Type extends MessageType<ConfigRequest> {
    constructor() {
        super("rs.devtools.tauri.ConfigRequest", []);
    }
    create(value?: PartialMessage<ConfigRequest>): ConfigRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ConfigRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConfigRequest): ConfigRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ConfigRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.tauri.ConfigRequest
 */
export const ConfigRequest = new ConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetricsRequest$Type extends MessageType<MetricsRequest> {
    constructor() {
        super("rs.devtools.tauri.MetricsRequest", []);
    }
    create(value?: PartialMessage<MetricsRequest>): MetricsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<MetricsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MetricsRequest): MetricsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MetricsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.tauri.MetricsRequest
 */
export const MetricsRequest = new MetricsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Config$Type extends MessageType<Config> {
    constructor() {
        super("rs.devtools.tauri.Config", [
            { no: 1, name: "raw", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Config>): Config {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.raw = "";
        if (value !== undefined)
            reflectionMergePartial<Config>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Config): Config {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string raw */ 1:
                    message.raw = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Config, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string raw = 1; */
        if (message.raw !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.raw);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.tauri.Config
 */
export const Config = new Config$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Metrics$Type extends MessageType<Metrics> {
    constructor() {
        super("rs.devtools.tauri.Metrics", [
            { no: 1, name: "initialized_at", kind: "message", T: () => Timestamp },
            { no: 2, name: "ready_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<Metrics>): Metrics {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Metrics>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Metrics): Metrics {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp initialized_at */ 1:
                    message.initializedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.initializedAt);
                    break;
                case /* google.protobuf.Timestamp ready_at */ 2:
                    message.readyAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.readyAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Metrics, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp initialized_at = 1; */
        if (message.initializedAt)
            Timestamp.internalBinaryWrite(message.initializedAt, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp ready_at = 2; */
        if (message.readyAt)
            Timestamp.internalBinaryWrite(message.readyAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.tauri.Metrics
 */
export const Metrics = new Metrics$Type();
/**
 * @generated ServiceType for protobuf service rs.devtools.tauri.Tauri
 */
export const Tauri = new ServiceType("rs.devtools.tauri.Tauri", [
    { name: "GetVersions", options: {}, I: VersionsRequest, O: Versions },
    { name: "GetConfig", options: {}, I: ConfigRequest, O: Config },
    { name: "GetMetrics", options: {}, I: MetricsRequest, O: Metrics }
]);
