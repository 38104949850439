// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "meta.proto" (package "rs.devtools.meta", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message rs.devtools.meta.AppMetadataRequest
 */
export interface AppMetadataRequest {
}
/**
 * @generated from protobuf message rs.devtools.meta.AppMetadata
 */
export interface AppMetadata {
    /**
     * / The apps name.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * / The apps version.
     *
     * @generated from protobuf field: string version = 2;
     */
    version: string;
    /**
     * / The apps authors.
     *
     * @generated from protobuf field: string authors = 3;
     */
    authors: string;
    /**
     * / The apps description.
     *
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * / The Operating System the app is running on.
     * /
     * / Possible values are:
     * / - "windows"
     * / - "macos"
     * / - "ios"
     * / - "linux"
     * / - "android"
     *
     * @generated from protobuf field: string os = 5;
     */
    os: string;
    /**
     * / The CPU architecture the app is running on.
     * /
     * / Possible values include:
     * / - "x86_64"
     * / - "aarch64"
     * / - "x86"
     * / - "arm"
     *
     * @generated from protobuf field: string arch = 6;
     */
    arch: string;
    /**
     * / Whether the app was compiled with debug assertions enabled.
     *
     * @generated from protobuf field: bool debug_assertions = 7;
     */
    debugAssertions: boolean;
    /**
     * / Whether the app has embedded assets or not.
     *
     * @generated from protobuf field: bool has_embedded_assets = 8;
     */
    hasEmbeddedAssets: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class AppMetadataRequest$Type extends MessageType<AppMetadataRequest> {
    constructor() {
        super("rs.devtools.meta.AppMetadataRequest", []);
    }
    create(value?: PartialMessage<AppMetadataRequest>): AppMetadataRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AppMetadataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppMetadataRequest): AppMetadataRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AppMetadataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.meta.AppMetadataRequest
 */
export const AppMetadataRequest = new AppMetadataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppMetadata$Type extends MessageType<AppMetadata> {
    constructor() {
        super("rs.devtools.meta.AppMetadata", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "authors", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "os", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "arch", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "debug_assertions", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "has_embedded_assets", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<AppMetadata>): AppMetadata {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.version = "";
        message.authors = "";
        message.description = "";
        message.os = "";
        message.arch = "";
        message.debugAssertions = false;
        message.hasEmbeddedAssets = false;
        if (value !== undefined)
            reflectionMergePartial<AppMetadata>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppMetadata): AppMetadata {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string version */ 2:
                    message.version = reader.string();
                    break;
                case /* string authors */ 3:
                    message.authors = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* string os */ 5:
                    message.os = reader.string();
                    break;
                case /* string arch */ 6:
                    message.arch = reader.string();
                    break;
                case /* bool debug_assertions */ 7:
                    message.debugAssertions = reader.bool();
                    break;
                case /* bool has_embedded_assets */ 8:
                    message.hasEmbeddedAssets = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppMetadata, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string version = 2; */
        if (message.version !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.version);
        /* string authors = 3; */
        if (message.authors !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.authors);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* string os = 5; */
        if (message.os !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.os);
        /* string arch = 6; */
        if (message.arch !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.arch);
        /* bool debug_assertions = 7; */
        if (message.debugAssertions !== false)
            writer.tag(7, WireType.Varint).bool(message.debugAssertions);
        /* bool has_embedded_assets = 8; */
        if (message.hasEmbeddedAssets !== false)
            writer.tag(8, WireType.Varint).bool(message.hasEmbeddedAssets);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.meta.AppMetadata
 */
export const AppMetadata = new AppMetadata$Type();
/**
 * @generated ServiceType for protobuf service rs.devtools.meta.Metadata
 */
export const Metadata = new ServiceType("rs.devtools.meta.Metadata", [
    { name: "GetAppMetadata", options: {}, I: AppMetadataRequest, O: AppMetadata }
]);
