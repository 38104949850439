// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "tauri.proto" (package "rs.devtools.tauri", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Tauri } from "./tauri";
import type { Metrics } from "./tauri";
import type { MetricsRequest } from "./tauri";
import type { Config } from "./tauri";
import type { ConfigRequest } from "./tauri";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Versions } from "./tauri";
import type { VersionsRequest } from "./tauri";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service rs.devtools.tauri.Tauri
 */
export interface ITauriClient {
    /**
     * @generated from protobuf rpc: GetVersions(rs.devtools.tauri.VersionsRequest) returns (rs.devtools.tauri.Versions);
     */
    getVersions(input: VersionsRequest, options?: RpcOptions): UnaryCall<VersionsRequest, Versions>;
    /**
     * @generated from protobuf rpc: GetConfig(rs.devtools.tauri.ConfigRequest) returns (rs.devtools.tauri.Config);
     */
    getConfig(input: ConfigRequest, options?: RpcOptions): UnaryCall<ConfigRequest, Config>;
    /**
     * @deprecated
     * @generated from protobuf rpc: GetMetrics(rs.devtools.tauri.MetricsRequest) returns (rs.devtools.tauri.Metrics);
     */
    getMetrics(input: MetricsRequest, options?: RpcOptions): UnaryCall<MetricsRequest, Metrics>;
}
/**
 * @generated from protobuf service rs.devtools.tauri.Tauri
 */
export class TauriClient implements ITauriClient, ServiceInfo {
    typeName = Tauri.typeName;
    methods = Tauri.methods;
    options = Tauri.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetVersions(rs.devtools.tauri.VersionsRequest) returns (rs.devtools.tauri.Versions);
     */
    getVersions(input: VersionsRequest, options?: RpcOptions): UnaryCall<VersionsRequest, Versions> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<VersionsRequest, Versions>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetConfig(rs.devtools.tauri.ConfigRequest) returns (rs.devtools.tauri.Config);
     */
    getConfig(input: ConfigRequest, options?: RpcOptions): UnaryCall<ConfigRequest, Config> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ConfigRequest, Config>("unary", this._transport, method, opt, input);
    }
    /**
     * @deprecated
     * @generated from protobuf rpc: GetMetrics(rs.devtools.tauri.MetricsRequest) returns (rs.devtools.tauri.Metrics);
     */
    getMetrics(input: MetricsRequest, options?: RpcOptions): UnaryCall<MetricsRequest, Metrics> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<MetricsRequest, Metrics>("unary", this._transport, method, opt, input);
    }
}
