// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "spans.proto" (package "rs.devtools.spans", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "./google/protobuf/timestamp";
import { Field } from "./common";
/**
 * @generated from protobuf message rs.devtools.spans.Update
 */
export interface Update {
    /**
     * A list of span events that happened since the last update.
     *
     * @generated from protobuf field: repeated rs.devtools.spans.SpanEvent span_events = 1;
     */
    spanEvents: SpanEvent[];
    /**
     * A count of how many log events were dropped because
     * the event buffer was at capacity.
     *
     * If everything is working correctly, this should be 0. If this
     * number is greater than zero this indicates the event buffers capacity
     * should be increased or the publish interval decreased.
     *
     * @generated from protobuf field: uint64 dropped_events = 2;
     */
    droppedEvents: bigint;
}
/**
 * A span event
 *
 * Span events are emitted whenever a span lifecycle event happens and are thus rather low-level by nature.
 *
 * @generated from protobuf message rs.devtools.spans.SpanEvent
 */
export interface SpanEvent {
    /**
     * @generated from protobuf oneof: event
     */
    event: {
        oneofKind: "newSpan";
        /**
         * @generated from protobuf field: rs.devtools.spans.SpanEvent.Span new_span = 1;
         */
        newSpan: SpanEvent_Span;
    } | {
        oneofKind: "enterSpan";
        /**
         * @generated from protobuf field: rs.devtools.spans.SpanEvent.Enter enter_span = 2;
         */
        enterSpan: SpanEvent_Enter;
    } | {
        oneofKind: "exitSpan";
        /**
         * @generated from protobuf field: rs.devtools.spans.SpanEvent.Exit exit_span = 3;
         */
        exitSpan: SpanEvent_Exit;
    } | {
        oneofKind: "closeSpan";
        /**
         * @generated from protobuf field: rs.devtools.spans.SpanEvent.Close close_span = 4;
         */
        closeSpan: SpanEvent_Close;
    } | {
        oneofKind: "recorded";
        /**
         * @generated from protobuf field: rs.devtools.spans.SpanEvent.Recorded recorded = 5;
         */
        recorded: SpanEvent_Recorded;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Represents a period of time in which a program was executing in a particular context.
 *
 * Corresponds to `Span` in the `tracing` crate.
 *
 * @generated from protobuf message rs.devtools.spans.SpanEvent.Span
 */
export interface SpanEvent_Span {
    /**
     * An Id that uniquely identifies it in relation to other spans.
     *
     * @generated from protobuf field: uint64 id = 1;
     */
    id: bigint;
    /**
     * Identifier for metadata describing static characteristics of all spans originating
     * from that call site, such as its name, source code location, verbosity level, and
     * the names of its fields.
     *
     * @generated from protobuf field: uint64 metadata_id = 2;
     */
    metadataId: bigint;
    /**
     * User-defined key-value pairs of arbitrary data that describe the context the span represents.
     *
     * @generated from protobuf field: repeated rs.devtools.common.Field fields = 3;
     */
    fields: Field[];
    /**
     * @generated from protobuf field: optional uint64 parent = 4;
     */
    parent?: bigint;
    /**
     * Timestamp for the span.
     *
     * @generated from protobuf field: google.protobuf.Timestamp at = 5;
     */
    at?: Timestamp;
}
/**
 * @generated from protobuf message rs.devtools.spans.SpanEvent.Enter
 */
export interface SpanEvent_Enter {
    /**
     * @generated from protobuf field: uint64 span_id = 1;
     */
    spanId: bigint;
    /**
     * @generated from protobuf field: uint64 thread_id = 2;
     */
    threadId: bigint;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp at = 3;
     */
    at?: Timestamp;
}
/**
 * @generated from protobuf message rs.devtools.spans.SpanEvent.Exit
 */
export interface SpanEvent_Exit {
    /**
     * @generated from protobuf field: uint64 span_id = 1;
     */
    spanId: bigint;
    /**
     * @generated from protobuf field: uint64 thread_id = 2;
     */
    threadId: bigint;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp at = 3;
     */
    at?: Timestamp;
}
/**
 * @generated from protobuf message rs.devtools.spans.SpanEvent.Close
 */
export interface SpanEvent_Close {
    /**
     * @generated from protobuf field: uint64 span_id = 1;
     */
    spanId: bigint;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp at = 3;
     */
    at?: Timestamp;
}
/**
 * Span recorded values for a list of fields.
 *
 * @generated from protobuf message rs.devtools.spans.SpanEvent.Recorded
 */
export interface SpanEvent_Recorded {
    /**
     * An Id that uniquely identifies it in relation to other spans.
     *
     * @generated from protobuf field: uint64 span_id = 1;
     */
    spanId: bigint;
    /**
     * Data recorded by the span.
     *
     * @generated from protobuf field: repeated rs.devtools.common.Field fields = 2;
     */
    fields: Field[];
}
// @generated message type with reflection information, may provide speed optimized methods
class Update$Type extends MessageType<Update> {
    constructor() {
        super("rs.devtools.spans.Update", [
            { no: 1, name: "span_events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SpanEvent },
            { no: 2, name: "dropped_events", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<Update>): Update {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.spanEvents = [];
        message.droppedEvents = 0n;
        if (value !== undefined)
            reflectionMergePartial<Update>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Update): Update {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rs.devtools.spans.SpanEvent span_events */ 1:
                    message.spanEvents.push(SpanEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint64 dropped_events */ 2:
                    message.droppedEvents = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Update, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rs.devtools.spans.SpanEvent span_events = 1; */
        for (let i = 0; i < message.spanEvents.length; i++)
            SpanEvent.internalBinaryWrite(message.spanEvents[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* uint64 dropped_events = 2; */
        if (message.droppedEvents !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.droppedEvents);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.spans.Update
 */
export const Update = new Update$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpanEvent$Type extends MessageType<SpanEvent> {
    constructor() {
        super("rs.devtools.spans.SpanEvent", [
            { no: 1, name: "new_span", kind: "message", oneof: "event", T: () => SpanEvent_Span },
            { no: 2, name: "enter_span", kind: "message", oneof: "event", T: () => SpanEvent_Enter },
            { no: 3, name: "exit_span", kind: "message", oneof: "event", T: () => SpanEvent_Exit },
            { no: 4, name: "close_span", kind: "message", oneof: "event", T: () => SpanEvent_Close },
            { no: 5, name: "recorded", kind: "message", oneof: "event", T: () => SpanEvent_Recorded }
        ]);
    }
    create(value?: PartialMessage<SpanEvent>): SpanEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<SpanEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SpanEvent): SpanEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rs.devtools.spans.SpanEvent.Span new_span */ 1:
                    message.event = {
                        oneofKind: "newSpan",
                        newSpan: SpanEvent_Span.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).newSpan)
                    };
                    break;
                case /* rs.devtools.spans.SpanEvent.Enter enter_span */ 2:
                    message.event = {
                        oneofKind: "enterSpan",
                        enterSpan: SpanEvent_Enter.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).enterSpan)
                    };
                    break;
                case /* rs.devtools.spans.SpanEvent.Exit exit_span */ 3:
                    message.event = {
                        oneofKind: "exitSpan",
                        exitSpan: SpanEvent_Exit.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).exitSpan)
                    };
                    break;
                case /* rs.devtools.spans.SpanEvent.Close close_span */ 4:
                    message.event = {
                        oneofKind: "closeSpan",
                        closeSpan: SpanEvent_Close.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).closeSpan)
                    };
                    break;
                case /* rs.devtools.spans.SpanEvent.Recorded recorded */ 5:
                    message.event = {
                        oneofKind: "recorded",
                        recorded: SpanEvent_Recorded.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).recorded)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SpanEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rs.devtools.spans.SpanEvent.Span new_span = 1; */
        if (message.event.oneofKind === "newSpan")
            SpanEvent_Span.internalBinaryWrite(message.event.newSpan, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rs.devtools.spans.SpanEvent.Enter enter_span = 2; */
        if (message.event.oneofKind === "enterSpan")
            SpanEvent_Enter.internalBinaryWrite(message.event.enterSpan, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rs.devtools.spans.SpanEvent.Exit exit_span = 3; */
        if (message.event.oneofKind === "exitSpan")
            SpanEvent_Exit.internalBinaryWrite(message.event.exitSpan, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rs.devtools.spans.SpanEvent.Close close_span = 4; */
        if (message.event.oneofKind === "closeSpan")
            SpanEvent_Close.internalBinaryWrite(message.event.closeSpan, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rs.devtools.spans.SpanEvent.Recorded recorded = 5; */
        if (message.event.oneofKind === "recorded")
            SpanEvent_Recorded.internalBinaryWrite(message.event.recorded, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.spans.SpanEvent
 */
export const SpanEvent = new SpanEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpanEvent_Span$Type extends MessageType<SpanEvent_Span> {
    constructor() {
        super("rs.devtools.spans.SpanEvent.Span", [
            { no: 1, name: "id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "metadata_id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "fields", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Field },
            { no: 4, name: "parent", kind: "scalar", opt: true, T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<SpanEvent_Span>): SpanEvent_Span {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0n;
        message.metadataId = 0n;
        message.fields = [];
        if (value !== undefined)
            reflectionMergePartial<SpanEvent_Span>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SpanEvent_Span): SpanEvent_Span {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 id */ 1:
                    message.id = reader.uint64().toBigInt();
                    break;
                case /* uint64 metadata_id */ 2:
                    message.metadataId = reader.uint64().toBigInt();
                    break;
                case /* repeated rs.devtools.common.Field fields */ 3:
                    message.fields.push(Field.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* optional uint64 parent */ 4:
                    message.parent = reader.uint64().toBigInt();
                    break;
                case /* google.protobuf.Timestamp at */ 5:
                    message.at = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.at);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SpanEvent_Span, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.id);
        /* uint64 metadata_id = 2; */
        if (message.metadataId !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.metadataId);
        /* repeated rs.devtools.common.Field fields = 3; */
        for (let i = 0; i < message.fields.length; i++)
            Field.internalBinaryWrite(message.fields[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* optional uint64 parent = 4; */
        if (message.parent !== undefined)
            writer.tag(4, WireType.Varint).uint64(message.parent);
        /* google.protobuf.Timestamp at = 5; */
        if (message.at)
            Timestamp.internalBinaryWrite(message.at, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.spans.SpanEvent.Span
 */
export const SpanEvent_Span = new SpanEvent_Span$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpanEvent_Enter$Type extends MessageType<SpanEvent_Enter> {
    constructor() {
        super("rs.devtools.spans.SpanEvent.Enter", [
            { no: 1, name: "span_id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "thread_id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<SpanEvent_Enter>): SpanEvent_Enter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.spanId = 0n;
        message.threadId = 0n;
        if (value !== undefined)
            reflectionMergePartial<SpanEvent_Enter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SpanEvent_Enter): SpanEvent_Enter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 span_id */ 1:
                    message.spanId = reader.uint64().toBigInt();
                    break;
                case /* uint64 thread_id */ 2:
                    message.threadId = reader.uint64().toBigInt();
                    break;
                case /* google.protobuf.Timestamp at */ 3:
                    message.at = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.at);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SpanEvent_Enter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 span_id = 1; */
        if (message.spanId !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.spanId);
        /* uint64 thread_id = 2; */
        if (message.threadId !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.threadId);
        /* google.protobuf.Timestamp at = 3; */
        if (message.at)
            Timestamp.internalBinaryWrite(message.at, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.spans.SpanEvent.Enter
 */
export const SpanEvent_Enter = new SpanEvent_Enter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpanEvent_Exit$Type extends MessageType<SpanEvent_Exit> {
    constructor() {
        super("rs.devtools.spans.SpanEvent.Exit", [
            { no: 1, name: "span_id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "thread_id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<SpanEvent_Exit>): SpanEvent_Exit {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.spanId = 0n;
        message.threadId = 0n;
        if (value !== undefined)
            reflectionMergePartial<SpanEvent_Exit>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SpanEvent_Exit): SpanEvent_Exit {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 span_id */ 1:
                    message.spanId = reader.uint64().toBigInt();
                    break;
                case /* uint64 thread_id */ 2:
                    message.threadId = reader.uint64().toBigInt();
                    break;
                case /* google.protobuf.Timestamp at */ 3:
                    message.at = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.at);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SpanEvent_Exit, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 span_id = 1; */
        if (message.spanId !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.spanId);
        /* uint64 thread_id = 2; */
        if (message.threadId !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.threadId);
        /* google.protobuf.Timestamp at = 3; */
        if (message.at)
            Timestamp.internalBinaryWrite(message.at, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.spans.SpanEvent.Exit
 */
export const SpanEvent_Exit = new SpanEvent_Exit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpanEvent_Close$Type extends MessageType<SpanEvent_Close> {
    constructor() {
        super("rs.devtools.spans.SpanEvent.Close", [
            { no: 1, name: "span_id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<SpanEvent_Close>): SpanEvent_Close {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.spanId = 0n;
        if (value !== undefined)
            reflectionMergePartial<SpanEvent_Close>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SpanEvent_Close): SpanEvent_Close {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 span_id */ 1:
                    message.spanId = reader.uint64().toBigInt();
                    break;
                case /* google.protobuf.Timestamp at */ 3:
                    message.at = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.at);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SpanEvent_Close, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 span_id = 1; */
        if (message.spanId !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.spanId);
        /* google.protobuf.Timestamp at = 3; */
        if (message.at)
            Timestamp.internalBinaryWrite(message.at, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.spans.SpanEvent.Close
 */
export const SpanEvent_Close = new SpanEvent_Close$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpanEvent_Recorded$Type extends MessageType<SpanEvent_Recorded> {
    constructor() {
        super("rs.devtools.spans.SpanEvent.Recorded", [
            { no: 1, name: "span_id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "fields", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Field }
        ]);
    }
    create(value?: PartialMessage<SpanEvent_Recorded>): SpanEvent_Recorded {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.spanId = 0n;
        message.fields = [];
        if (value !== undefined)
            reflectionMergePartial<SpanEvent_Recorded>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SpanEvent_Recorded): SpanEvent_Recorded {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 span_id */ 1:
                    message.spanId = reader.uint64().toBigInt();
                    break;
                case /* repeated rs.devtools.common.Field fields */ 2:
                    message.fields.push(Field.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SpanEvent_Recorded, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 span_id = 1; */
        if (message.spanId !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.spanId);
        /* repeated rs.devtools.common.Field fields = 2; */
        for (let i = 0; i < message.fields.length; i++)
            Field.internalBinaryWrite(message.fields[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rs.devtools.spans.SpanEvent.Recorded
 */
export const SpanEvent_Recorded = new SpanEvent_Recorded$Type();
