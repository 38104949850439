// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "instrument.proto" (package "rs.devtools.instrument", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Instrument } from "./instrument";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Update } from "./instrument";
import type { InstrumentRequest } from "./instrument";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Real time updates about components of an instrumented application.
 *
 * @generated from protobuf service rs.devtools.instrument.Instrument
 */
export interface IInstrumentClient {
    /**
     * Produces a stream of updates about the behavior of the  instrumented application.
     *
     * @generated from protobuf rpc: WatchUpdates(rs.devtools.instrument.InstrumentRequest) returns (stream rs.devtools.instrument.Update);
     */
    watchUpdates(input: InstrumentRequest, options?: RpcOptions): ServerStreamingCall<InstrumentRequest, Update>;
}
/**
 * Real time updates about components of an instrumented application.
 *
 * @generated from protobuf service rs.devtools.instrument.Instrument
 */
export class InstrumentClient implements IInstrumentClient, ServiceInfo {
    typeName = Instrument.typeName;
    methods = Instrument.methods;
    options = Instrument.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Produces a stream of updates about the behavior of the  instrumented application.
     *
     * @generated from protobuf rpc: WatchUpdates(rs.devtools.instrument.InstrumentRequest) returns (stream rs.devtools.instrument.Update);
     */
    watchUpdates(input: InstrumentRequest, options?: RpcOptions): ServerStreamingCall<InstrumentRequest, Update> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<InstrumentRequest, Update>("serverStreaming", this._transport, method, opt, input);
    }
}
